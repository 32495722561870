<template>
	<el-row class="container">
		<!-- *********************** 头部*************************************** -->
		<!-- <Header ref="Header"></Header> -->
		<!-- <el-row id="head">
		<el-col :span="6">
			<span class="white_nowrap" style="font-weight:650;">物流追踪系统</span>
		</el-col> -->
		<!-- <el-header> -->
		<!-- <el-col :span="12"> -->
		<el-menu :default-active="activeName" ref="openText" class="el-menu-vertical-demo" mode="horizontal" id="sidebar" theme="dark" unique-opened router>
			<!-- <el-menu ref="openText" class="el-menu-vertical-demo" id="sidebar" theme="dark" unique-opened router> -->
			<el-menu-item style="width: 20%;font-size: 24px;">物流追踪系统</el-menu-item>

			<template v-for="(item,index) in $router.options.routes" :index="index">
				<div v-if="!item.hidden&&isRole(item.pow)" :key="index">
					<el-submenu :index="item.path+index" v-if="!item.leaf&&!item.isShow&&isRole(item.pow)">
						<!-- 一级页面目录 -->
						<template slot="title" icon="el-icon-search">
							<i :class="item.ico"></i>
							{{item.name}}
						</template>
						<!-- 二级页面目录 -->
						<template v-for="(child,index) in item.children">
							<el-menu-item v-if="!child.hidden&& (!child.children)&&isRole(child.pow)" :index="child.path" :key="index">
								<!-- <i :class="child.ico"></i> -->
								{{child.name}}
							</el-menu-item>

							<el-submenu :index="child.path+index" v-if="child.children&&isRole(child.pow)" :key="index">
								<template slot="title">
									<i :class="child.ico"></i>
									{{child.name}}
								</template>
								<!-- <el-menu-item v-for="childs in child.children" v-if="!childs.hidden" :index="childs.path"><i :class="childs.ico"></i>{{childs.name}}</el-menu-item> -->
							</el-submenu>
						</template>
					</el-submenu>
					<!-- 没有二级页面的一级页面目录 -->
					<el-menu-item v-if="item.leaf && item.children.length>0&&isRole(item.pow)" :index="item.children[0].path">
						<i :class="item.children[0].ico"></i>
						{{item.children[0].name}}
					</el-menu-item>
				</div>
			</template>

			<el-submenu style="position: absolute;right: 0;">
				<template slot="title">{{name}}</template>
				<el-menu-item @click.native="backLogin">退出登录</el-menu-item>
			</el-submenu>
		</el-menu>
		<!-- </el-col>
		<el-col :span="6">
			
		</el-col>
		</el-row> -->
		<!-- </el-header> -->

		<el-col :span="24" class="main">
			<!-- *********************** 侧边栏*************************************** -->
			<!-- <aside> -->

			<!-- </aside> -->

			<section class="content-container">
				<div class="h-100">
					<!-- *********************** 面包屑*************************************** -->
					<!-- <el-col :span="24" class="breadcrumb-container">
						<strong class="fs_14 fc_111">{{$route.name}}</strong>
						<el-breadcrumb separator="/" class="breadcrumb-inner">
							<el-breadcrumb-item v-for="item in $route.matched">
								{{ item.name }}

							</el-breadcrumb-item>
						</el-breadcrumb>
          </el-col>-->
					<!-- *********************** 主体*************************************** -->
					<el-col :span="24">
						<transition>
							<router-view></router-view>
						</transition>
					</el-col>
				</div>
			</section>
		</el-col>
	</el-row>
</template>

<script>
	/* 头部组件 */
	// import Header from "@/views/public/header/header";
	import data from "./data";
	import methods from "./methods";

	export default {
		data() {
			return data.init();
		},
		components: {
			// Header
		},
		methods: methods,
		mounted() {
			this.name = JSON.parse(localStorage.getItem(this.sy)).name
		},
		created() {},
		computed: {
			activeName() {
				var path = this.$route.path;
				return path;
			}
		},
		filters: {
			isSystem: function() {
				return false;
			}
		},
		//created() {}
	};
</script>
<style lang="less">
	.container {
		position: absolute;
		top: 0px;
		bottom: 0px;
		width: 100%;
		height: 100vh;

		#sidebar {
			display: flex;
		}

		.main {
			background: #1a1b20;
			position: absolute;
			top: 60px;
			bottom: 0px;
			overflow: hidden;

			aside {
				width: 180px;
			}

			.el-menu {
				border: none;
			}

			.content-container {
				background: #f1f2f7;
				position: absolute;
				right: 0px;
				top: 0px;
				bottom: 0px;
				left: 0px;
				overflow-x: hidden;
				overflow-y: auto;
				padding: 20px;

				.breadcrumb-container {
					margin-bottom: 15px;

					.title {
						width: 200px;
						float: left;
						color: #475669;
					}

					.breadcrumb-inner {
						float: right;
					}
				}

				.content-wrapper {
					background-color: #fff;
					box-sizing: border-box;
				}
			}
		}
	}

	#tab-0 .el-icon-close,
	.el-tabs__new-tab {
		//
		display: none !important;
		opacity: 0 !important;
	}

	#head,
	.el-submenu__title,
	.el-menu-item,
	.el-menu {
		color: rgba(255, 255, 255, 0.74) !important;
		background: #1a1b20 !important;
		min-width: 160px;
	}

	.el-menu-item:first-child:hover,
	.el-submenu:first-child:hover {
		color: rgba(255, 255, 255, 0.74) !important;
		background: #1a1b20 !important;
		cursor: default;
	}

	.el-menu-item:hover {
		background: rgb(0, 150, 136) !important;
		// cursor: pointer;
	}

	.el-menu .is-active {
		background: rgb(0, 150, 136) !important;
	}

	.el-submenu .el-menu-item {
		min-width: 100px !important;
	}
	
	
	
	.el-menu--horizontal>.el-menu-item {
		height: auto;
	}
</style>
